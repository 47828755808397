<template>
    <v-lazy v-model="isActive"
            :options="{ threshold: .5 }"
            transition="fade-transition">

        <v-card color="#FFF"
                rounded="lg"
                id="cardInfo"
                :to="pUrl">

            <div class="d-flex flex-row" id="cardInfoContent">
                <v-icon :color="pColor"
                        x-large
                        class="mr-1">
                    {{pIcon}}
                </v-icon>

                <div class="d-flex flex-column">
                    <div id="cardTitleInfoCard"
                         :style=getStyleColor
                         class="text-left">
                        {{valueToString}}
                    </div>

                    <div id="cardSubtitleInfoCard"
                         class="text-left">
                        {{pKey}}
                    </div>

                </div>

            </div>

        </v-card>

    </v-lazy>
</template>

<script>
    import { mdiFile } from '@mdi/js';

    export default {
        name: 'InfoCard',
        data: () => {
            return {
                isActive: false,
            };
        },
        props: {
            pName: {
                type: String,
                required: true
            },
            pKey: {
                type: String,
                required: true
            },
            pValue: {
                type: Number,
                required: true
            },
            pColor: {
                type: String,
                default: "#0D8C1A",
            },
            pIcon: {
                type: String,
                default: mdiFile,
            },
            pUrl: {
                type: String,
                default: "/"
            }
        },
        computed: {
            valueToFloat: function () {
                if (this.pUrl === "/emenda-constitucional" || this.pUrl === "/ato-atualizado") return parseFloat(this.pValue+1);
                else return parseFloat(this.pValue)
            },
            valueToString: function () {
                return this.valueToFloat.toLocaleString();
            },
            getIconAlt: function () {
                return `�cone ${this.pName}`;
            },
            getStyleColor: function () {
                return `color: ${this.pColor}`;
            },
        }
    }
</script>

<style>
    @import './InfoCard.css';
</style>
