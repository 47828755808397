<template>
  <div>
    <div
      v-if="['md', 'lg', 'xl'].includes($vuetify.breakpoint.name)"
      :style="`margin-top: ${3 * (index + 1.25)}%;`"
    >
      <div
        :class="['modal-aviso-atos', show ? 'visible' : '']"
        :style="`${show ? 'z-index: ' + (10 - index) : ''}`"
      >
        <div :class="`aviso text-left ${cor}`" v-html="desc"></div>
        <v-btn
          small
          :class="`position width ${cor}`"
          v-on:click="show = !show"
          elevation="0"
        >
          <v-icon>
            {{ icons.mdiInformation }}
          </v-icon>
        </v-btn>
      </div>
    </div>

    <div v-else>
      <v-app id="inspire">
        <div class="mobile">
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :class="`${cor} border-radius`"
                v-bind="attrs"
                v-on="on"
                :style="`z-index: 10; border-radius: 0px !important; border-bottom-left-radius: 5px !important; border-top-left-radius: 5px !important;`"
              >
                <v-icon color="#fff">
                  {{ icons.mdiInformation }}
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">Aviso</v-card-title>
              <v-card-text class="mobile-texto" v-html="desc"> </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </v-app>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from "@mdi/js";
import { mdiInformation } from "@mdi/js";

export default {
  data: () => ({
    show: false,
    dialog: false,
    icons: {
      mdiAlert,
      mdiInformation,
    },
    description: "",
    color: "",
  }),
  props: {
    desc: String,
    cor: String,
    index: Number,
  },
};
</script>

<style scoped>
/* Estilo Web */
.modal-aviso-atos {
  display: flex;
  position: fixed;
  left: -400px;
  transition: 1s;
  transition-timing-function: ease-in-out;
}
.modal-aviso-atos.visible {
  left: 0;
  transition: 1s;
  transition-timing-function: ease-in-out;
}
.aviso {
  padding: 10px;
  font-size: 1.7rem;
  width: 400px;
  border-radius: 0px 0px 5px 0px;
}
.infoc {
  background-color: #a4aab1 !important;
  color: #fff !important;
}
[data-theme="darkMode"] .infoc {
  filter: invert(100%) hue-rotate(180deg);
}
.dangerc {
  background-color: #fc5d5d !important;
  color: #fff !important;
}
[data-theme="darkMode"] .dangerc {
  filter: invert(100%) hue-rotate(180deg);
}
.warningc {
  background-color: #f7d977 !important;
  color: #fff !important;
}
[data-theme="darkMode"] .warningc {
  filter: invert(100%) hue-rotate(180deg);
}
.successc {
  background-color: #7fcc78 !important;
  color: #fff !important;
}
[data-theme="darkMode"] .successc {
  filter: invert(100%) hue-rotate(180deg);
}
.width {
  width: 50px !important;
  height: 50px !important;
  border-radius: 0px 5px 5px 0px;
}
/* Estilo Mobile */
.mobile-cores {
  position: absolute;
  top: 30px;
  left: 0px;
  width: 5% !important;
}

.mobile-texto {
  margin-top: 10px;
}
.v-application--wrap {
  min-height: 0px !important;
  margin-bottom: 15px;
}
.mobile {
  display: flex !important;
  justify-content: end !important;
}
</style>
