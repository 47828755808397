<template>
  <div>
    <div
      class="flex-normal-size"
      v-if="['md', 'lg', 'xl'].includes($vuetify.breakpoint.name)"
      :style="`margin-top: 2%;`"
    >
      <div class="modal-aviso-atos" :style="`${show ? 'z-index: ' + 999 : ''}`">
        <v-btn
          small
          :class="` width ${cor}`"
          title="Conferir novidades"
          elevation="0"
          @click="dialog = !dialog"
        >
          <v-icon>
            {{ icons.mdiNewBox }}
          </v-icon>
        </v-btn>

        <!-- <div :class="`aviso ${cor}`"></div> -->
      </div>
    </div>

    <div v-else>
      <v-app>
        <div>
          <v-btn
            :class="`${cor} border-radius`"
            @click="dialog = !dialog"
            v-on="on"
            :style="`margin-top: 10%; z-index: 10;`"
          >
            <v-icon color="#fff">
              {{ icons.mdiNewBox }}
            </v-icon>
          </v-btn>
        </div>
      </v-app>
    </div>

    <v-dialog v-model="dialog" width="550">
      <v-card>
        <v-card-title class="card-title">
          <span>O que há de novo no LegisOn</span>
          <v-btn color="#fff" icon @click="dialog = false">
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="p-4 pb-3">
          <div v-if="features">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel v-for="(data, i) in dataDaFeature" :key="i">
                <v-expansion-panel-header class="header-font">
                  {{ data }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-left">
                  <div v-for="(dados, index) in features" :key="index">
                    <div v-if="checkDate(dados.data, data)" class="p-2">
                      {{ dados.descricao }}
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <div v-else class="h4 text-left mx-5">
            <div class="mb-2">Nada de novo adicionado no último mês.</div>
            <div>
              Continue de olho ou assine nosso Newsletter para se manter
              atualizado de qualquer mudança no sistema.
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiAlert, mdiNewBox, mdiClose, mdiMenuRight } from "@mdi/js";

import moment from "moment";

export default {
  data: () => ({
    panel: [],
    items: 5,
    show: false,
    dialog: false,
    dataDaFeature: [],
    icons: {
      mdiAlert,
      mdiNewBox,
      mdiClose,
      mdiMenuRight,
    },
    color: "",
    features: "",
  }),
  props: {
    cor: String,
  },
  methods: {
    all() {
      this.panel = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panel = [];
    },
    toggleDialog: function () {
      this.dialog = !this.dialog;
    },
    getFeatures: function () {
      this.$http
        .get("/Novidades/GetFeaturesList")
        .then((response) => {
          this.features = response.data;
          this.dataDaFeature = this.features.map(({ data }) => data);

          let aux = [...new Set(this.dataDaFeature)];
          this.dataDaFeature = [];
          aux.forEach((element) => {
            element = element.split("T")[0];
            if (!this.dataDaFeature.includes(element))
              this.dataDaFeature.push(element);
          });
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(this.features);
    },
    handleDate: function (dateValue) {
      return new moment(dateValue).format("DD-MM-YYYY");
    },
    checkDate: function (data, dataDoLoop) {
      data = data.split("T")[0];
      if (data == dataDoLoop) return true;
      else return false;
    },
  },
  computed: {},
  mounted() {
    this.getFeatures();
  },
};
</script>

<style scoped>
/* Estilo Web */
.modal-aviso-atos {
  display: flex;
  position: fixed;
  margin-top: 2%;
}

.aviso {
  padding: 10px;
  font-size: 1.7rem;
  border-radius: 0px 0px 5px 0px;
}

.successc {
  background-color: #0d8c1a !important;
  color: #fff !important;
}
.width {
  width: 50px !important;
  height: 50px !important;
  border-radius: 5px 0px 0px 5px;
}
.card-title {
  display: flex;
  justify-content: space-between;

  background-color: #0d8c1a;
  color: #ffffff;

  padding: 1rem 2rem !important;

  font-size: 24px !important;
  font-weight: 400 !important;
}
/* Estilo Mobile */
.mobile-texto {
  margin-top: 10px;
}
.v-application--wrap {
  min-height: 0px !important;
  margin-bottom: 15px;
}

.border-radius {
  border-radius: 0px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.linha-novidade {
  display: flex;
  justify-content: space-between;
}
.header-font {
  font-size: 20px;
}
.flex-normal-size {
  display: flex;
  flex-direction: row-reverse;
}
</style>
