<template>
    <div class="Home h-100 d-flex flex-column" id="main">
        <header class="flex-shrink-1">
            <NavComponent :home="true"></NavComponent>
        </header>
        
        <div id="novidades">
            <NovidadesBanner cor="successc" />
        </div>
        
        <div id="banner">
            <div class="flex">
                <div v-for="(av, index) in avisos" :key="av.id" class="m-0">
                    <ButtonBanner :index="index" :desc="av.descricao" :cor="av.tipo + 'c'"/>
                </div>
            </div>
        </div>
        
        <div :style="`margin-top: -${avisos.length*2.25}%`">
            <div id="content" class="d-flex flex-column flex-shrink-1 align-items-center">
                <div id="logoImgs" class="d-flex justify-content-center">
                    <LogoImage pWidthLogo="25vw"
                        pMaxWidthLogo="360"
                        pWidthSubt="25vw"
                        pMaxWidthSubt="326" />
                </div>

                <div id="searchBar" class="w-75">
                    <SearchBar></SearchBar>
                </div>

                <div id="info" class="d-flex flex-wrap justify-content-center mb-4">

                    <InfoCard v-for="card in infoCards"
                        :key="card.id"
                        :pName="card.tipo"
                        :pKey="card.name"
                        :pValue="card.qtdAtos"
                        :pColor="card.color"
                        :pIcon="card.icon"
                        :pUrl="card.url">
                    </InfoCard>
                </div>
            </div>
        </div>
        
        <div class="flex-grow-1 mb-4"/>
                
        <Footer class="mt-4" />
        
        <!-- <Newsletter/> -->
    </div>
</template>

<script>
    import NavComponent from '@/shared/components/NavComponent.vue';
    import SearchBar from '@/shared/components/SearchBar.vue';
    import LogoImage from '@/shared/components/LogoImage.vue';
    import InfoCard from './components/InfoCard.vue';
    import Footer from '@/shared/components/Footer.vue';
    import ButtonBanner from '@/shared/components/ButtonBanner.vue';
    // import Newsletter from '../../shared/components/Newsletter.vue';
    import NovidadesBanner from '../../shared/components/NovidadesBanner.vue';
    import { mdiBook, mdiInformationOutline, mdiFileDocumentEdit, mdiFileDocument, mdiFeather } from '@mdi/js';

    const apiInfoCards = '/Public/GetStats';

    export default {
        name: 'Home',
        title: 'LegisOn',
        data: () => {
            return ({
                infoCards: [],
                loading: false,
                errored: false,
                error: null,
                mdiInformationOutline,
                avisos: [],
            })
        },
        beforeRouteLeave(to, from, next) {
            localStorage.setItem('prevPage', from.path);
            next();
        },
        components: {
            NavComponent,
            SearchBar,
            LogoImage,
            InfoCard,
            Footer,
            ButtonBanner,
            // Newsletter,
            NovidadesBanner
        },
        computed: {},
        methods: {
            getInfoCards: function () {
                this.loading = true;
                this.infoCards = [];
                this.$http.get(apiInfoCards)
                    .then(response => {
                      this.infoCards = response.data;
                      var arr = [];
                      var dn = this.infoCards.find(x => x.tipo.toLowerCase().startsWith("decreto"));
                      var lo = this.infoCards.find(x => x.tipo.toLowerCase().startsWith("lei ordinária"));
                      var lc = this.infoCards.find(x => x.tipo.toLowerCase().startsWith("lei complementar"));
                      var ec = this.infoCards.find(x => x.tipo.toLowerCase().startsWith("emenda"));
                      var ac = this.infoCards.find(x => x.tipo.toLowerCase().startsWith("atos"));
                      if (dn) arr.push(dn);
                      if (lo) arr.push(lo);
                      if (lc) arr.push(lc);
                      if (ec) arr.push(ec);
                      if (ac) arr.push(ac);
                      this.infoCards = arr;
                      this.prepareInfoCards();
                    })
                    .catch(error => {
                        this.errored = true;
                        this.errorMessage = error;
                    })
                    .finally(this.loading = false);
            },
            prepareInfoCards: function () {
              this.infoCards.map(item => {
                    switch (item.tipo) {
                        case 'Emenda Constitucional':
                            item.name = "Constituição Estadual e Emendas";
                            item.color = "#341F97";
                            item.icon = mdiBook;
                            item.url = "/emenda-constitucional"
                            break;
                        case 'Lei Complementar':
                            item.name = "Leis Complementares";
                            item.color = "#EE5253";
                            item.icon = mdiFeather;
                            item.url = "/lei-complementar";
                            break;
                        case 'Lei Ordinária':
                            item.name = "Leis Ordinárias";
                            item.color = "#EE8152";
                            item.icon = mdiFeather;
                            item.url = "/lei-ordinaria";
                            break;
                        case 'Decreto Numerado':
                            item.name = "Decretos Numerados";
                            item.color = "#1F976C";
                            item.icon = mdiFileDocument;
                            item.url = "/decreto-numerado";
                            break;
                        case 'Parecer Jurídico':
                            item.name = "Pareceres";
                            item.color = "#3252a8";
                            item.icon = mdiFileDocumentEdit;
                            item.url = "/";
                            break;
                        case 'Atos Consolidados':
                            item.name = "Atos Atualizados";
                            item.color = "#3252a8";
                            item.icon = mdiFileDocumentEdit;
                            item.url = "/ato-atualizado"
                            break;
                        default:
                            break;
                    }
                })
            },
            getAvisos: function () {
                this.$http.get("/Aviso/GetList").then(res => {
                    let danger = res.data.filter(x => x.tipo === "danger");
                    let warning = res.data.filter(x => x.tipo === "warning");
                    let success = res.data.filter(x => x.tipo === "success");
                    let info = res.data.filter(x => x.tipo === "info");
                    this.avisos = [...info, ...danger, ...warning, ...success];
                }).catch(() => {});
            }
        },
        beforeMount() {
            this.getAvisos();
        },
        mounted() {
            this.getInfoCards();
        }
    }
</script>

<style>
#novidades {
    margin-top: 5px;
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    justify-content: end
}

#banner {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    justify-content: end
}
    @import './Home.css';
</style>
